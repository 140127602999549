export const environment = {
    production: false,
    baseUrl8080: baseUrlPath(8080,'/hrms/v1/'),
    baseUrl8081: baseUrlPath(8081,'/hrms/v4/'),
    baseUrl8082: baseUrlPath(8082,'/hrms/v5/'),
    baseUrl8083: baseUrlPath(8083,'/hrms/v3/'),
    baseUrl8084: baseUrlPath(8084,'/hrms/v2/'),
    baseUrl8085: baseUrlPath(8085,'/hrms/v6/'),
    baseUrl8086: baseUrlPath(8086,'/hrms/v7/'),
    baseUrl8087: baseUrlPath(8087,'/hrms/v8/'),
    baseUrl8088: baseUrlPath(8088,'/hrms/v11/'),
    baseUrl8089: baseUrlPath(8089,'/hrms/v9/'),
    baseUrl8090: baseUrlPath(8090,'/hrms/l7/'),
    baseUrl8091: baseUrlPath(8091,'/hrms/v17/'),
    baseUrl8092: baseUrlPath(8092,'/hrms/v18/'),
    baseUrl8093: baseUrlPath(8093,'/hrms/v10/'),
    baseUrl8094: baseUrlPath(8094,'/hrms/v14/'),
    baseUrl8095: baseUrlPath(8095,'/hrms/v15/'),
    baseUrl8096: baseUrlPath(8096,'/hrms/v12/'),
    baseUrl8097: baseUrlPath(8097,'/hrms/v13/'),
    baseUrl8099: baseUrlPath(8099,'/hrms/v20/'),
    baseUrl8100: baseUrlPath(8100,'/hrms/v19/'),
  
  };
  
  function baseUrlPath(port,path) {
      let url = window.location.hostname; 
    if(url == 'localhost'){
        return `https://test.deliziahr.com:${port}/hrms/v1/`
    }else if(url == 'dev.deliziahr.com'){
        return `https://dev.deliziahr.com:${port}/hrms/v1/`
    }else if(url == 'test.deliziahr.com'){
        return `https://test.deliziahr.com:${port}/hrms/v1/`
    }else{
        return path
    }
  }
  
  